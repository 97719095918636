import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Button, FormGroup } from "shards-react"
import { useTranslation } from "react-i18next"
import { isEmpty, filter } from "lodash"
import { history, userRoleConstants, hasAuthority } from "../../../_helpers"
import { modalActions } from "../../modals"
import { applicationActions, ApplicationTableRowsActions } from ".."
import { authenticationActions } from "../../authentication"
import print from "../../../_helpers/print"
import "./ApplicationDetailsActions"
import "./ApplicationDetailsActions.scss"

const CREDIT_SCORE = "Credit Score"
const CASHFLOW_ANALYSIS = "Cashflow Analysis"
const VIEW_DATA = "View Data"
const COMMENTS = "Comments"

export function ApplicationDetailsActions({ id, change, handleSubmit }) {
  const state = useSelector((state) => state.applications)
  const applications = useSelector((state) => state.applications)
  const authentication = useSelector((state) => state.authentication)
  const {
    profile: {
      mfi: { mfiName },
    },
  } = authentication
  const { usersToAssign } = applications
  const dispatch = useDispatch()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const [canUpdateSubmission, setCanUpdateSubmission] = useState(false)
  const [canRecalculate, setCanRecalculate] = useState(false)
  const [canApprove, setCanApprove] = useState(false)
  const [canApproveAssign, setCanApproveAssign] = useState(false)
  const approvalUsers = !isEmpty(usersToAssign)
    ? usersToAssign.forward.map((user) => user.username)
    : []

  const { t } = useTranslation()
  const [fullSubmission, setFullSubmission] = useState({})
  const [selectedTabTextState, setSelectedTabTextState] = useState("")
  const [printText, setprintText] = useState("")
  const [printInProgress, setPrintInProgress] = useState(false)
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  useEffect(() => {
    const { items } = applications
    const foundApplication = filter(items, { id })
    const [application] = foundApplication
    foundApplication && setFullSubmission(application)
  }, [id, applications])

  useEffect(() => {
    if (isEmpty(fullSubmission)) {
      dispatch(applicationActions.getApplicationbyID(id))
    } else {
      dispatch(applicationActions.getLoanInformation(fullSubmission))
    }
  }, [dispatch, id, fullSubmission])

  useEffect(() => {
    dispatch(applicationActions.fetchUsersToAssign(id))
  }, [dispatch, id])

  useEffect(() => {
    if (!isEmpty(fullSubmission)) {
      const currentUsername = JSON.parse(localStorage.getItem("user")).user_name
      setCanUpdateSubmission(
        handleSubmit &&
          fullSubmission.workflowStatus.toUpperCase() === "REVIEW" &&
          mfiName !== "UGACOF" &&
          fullSubmission.currentUser ===
            JSON.parse(localStorage.getItem("user")).user_name
      )
      setCanRecalculate(
        fullSubmission.workflowStatus.toUpperCase() === "REVIEW" &&
          mfiName !== "UGACOF" &&
          (hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST) ||
            hasAuthority(userRoleConstants.MFI_SUPERVISOR))
      )
      setCanApprove(
        fullSubmission.workflowStatus.toUpperCase() === "REVIEW" &&
          fullSubmission.stage === userRoleConstants.MFI_CREDIT_ANALYST &&
          hasAuthority(userRoleConstants.MFI_CREDIT_ANALYST)
      )
      setCanApproveAssign(
        fullSubmission.workflowStatus.toUpperCase() === "REVIEW" &&
          fullSubmission.stage === userRoleConstants.MFI_SUPERVISOR &&
          approvalUsers.includes(currentUsername)
      )
    }
  }, [dispatch, fullSubmission, handleSubmit, approvalUsers])

  useEffect(() => {
    dispatch(authenticationActions.getProfile())
  }, [dispatch])

  const activeTabCheck = useCallback(() => {
    const selectedTabText = document.getElementsByClassName(
      "react-tabs__tab--selected"
    )[0]?.innerText

    if (
      (selectedTabText &&
        (selectedTabText === CREDIT_SCORE ||
          selectedTabText === CASHFLOW_ANALYSIS)) ||
      selectedTabText === VIEW_DATA ||
      selectedTabText === COMMENTS
    ) {
      setSelectedTabTextState([selectedTabText])
      setprintText(`Print ${selectedTabText}`)
    }
    return
  }, [setSelectedTabTextState])

  useEffect(() => {
    activeTabCheck()
  }, [activeTabCheck])

  useEffect(() => {
    function shouldEnableSubmitButton() {
      return !submitButtonDisabled
    }
    setSubmitButtonDisabled(!shouldEnableSubmitButton())
  }, [change, submitButtonDisabled, setSubmitButtonDisabled])

  const adjustAndRecalculate = () => {
    dispatch(modalActions.recalculateScore({ id, fullSubmission }))
  }

  const saveApplication = () => {
    handleSubmit()
  }

  const handleClose = () => {
    history.push("/applications")
    dispatch(
      applicationActions.applicationClicked({
        page: "ListApplication",
        history: true,
      })
    )
  }

  const approveApplication = () => {
    dispatch(
      modalActions.approve({
        approve: true,
        applicationId: id,
        limit: "",
      })
    )
  }

  const declineApplication = () => {
    dispatch(
      modalActions.approve({
        decline: true,
        applicationId: id,
        limit: "",
      })
    )
  }

  // handle the format for the document to be print
  const formatprint = (elementSelector) => {
    elementSelector[0].setAttribute("id", "printable")

    const newStyle = document.createElement("style")
    newStyle.setAttribute("type", "text/css")

    newStyle.appendChild(
      document.createTextNode(
        `@page {margin: 20mm 5mm 20mm 0}
        .fields__group-wrapper {
          grid-template-columns: 0.5fr 1fr !important;
        }
        .custom-control, .custom-radio {
          min-height: 1.5rem;
          padding-left: 1rem;
          margin-left:25px
        }
        .application-edit-form label {
          display: block;
        }
      `
      )
    )
    elementSelector[0].appendChild(newStyle)
    return elementSelector
  }

  const handlePrint = async (printFile = false) => {
    // include LO and submission time on printables
    const { submittedBy, submissionTime } = fullSubmission
    const humanReadableTime = new Date(submissionTime).toLocaleString()
    const pdfFilename = selectedTabTextState[0]
    let elementSelector
    let inner = ""
    if (printFile) setPrintInProgress(true)
    else setDownloadInProgress(true)
    if (selectedTabTextState[0] === CREDIT_SCORE) {
      elementSelector = document.getElementsByClassName("score-report-wrapper")
      const gridElementSelector = document.getElementsByClassName("grade-grids")
      const tablist = document.getElementsByClassName("pb-10")
      tablist[0].style.display = "none"
      // hide the grade meter
      const svgElements = document.body.querySelectorAll("svg")
      svgElements[4].setAttribute(
        "display",
        svgElements[4].getBoundingClientRect().display
      )
      svgElements[5].setAttribute(
        "display",
        svgElements[5].getBoundingClientRect().display
      )
      svgElements[4].style.display = "none"
      svgElements[5].style.display = "none"
      // Increase length of total points
      gridElementSelector[0].setAttribute(
        "grid-template-columns",
        gridElementSelector[0].getBoundingClientRect().gridTemplateColumns
      )
      gridElementSelector[0].style.gridTemplateColumns = "auto 180px"
      await print(
        `${state.applicantName}-${pdfFilename}`,
        elementSelector[0],
        dispatch,
        printFile,
        null,
        setPrintInProgress,
        setDownloadInProgress,
        submittedBy,
        humanReadableTime
      )
      // after downloading return all altered elements to original state
      svgElements[4].style.display = "block"
      svgElements[5].style.display = "block"
      gridElementSelector[0].style.gridTemplateColumns = "auto 120px"
    } else if (selectedTabTextState[0] === CASHFLOW_ANALYSIS) {
      elementSelector = document.getElementsByClassName("cashflow-analysis")

      await print(
        `${state.applicantName}-${pdfFilename}`,
        elementSelector[0],
        dispatch,
        printFile,
        null,
        setPrintInProgress,
        setDownloadInProgress,
        submittedBy,
        humanReadableTime
      )
    } else {
      elementSelector = document.getElementsByClassName("p-10")

      if (printFile) {
        inner = elementSelector[0].innerHTML
        elementSelector = await formatprint(elementSelector)
      }

      await print(
        `${state.applicantName}-${pdfFilename}`,
        elementSelector[0],
        dispatch,
        printFile,
        inner,
        setPrintInProgress,
        setDownloadInProgress,
        submittedBy,
        humanReadableTime
      )
    }
  }

  const canPrintorDownload = () => {
    let returnVal = false
    if (
      (selectedTabTextState[0] &&
        (selectedTabTextState[0] === CREDIT_SCORE ||
          selectedTabTextState[0] === CASHFLOW_ANALYSIS)) ||
      selectedTabTextState[0] === VIEW_DATA ||
      selectedTabTextState[0] === COMMENTS
    ) {
      returnVal = true
    }
    return returnVal
  }

  const currentpage = window.location.pathname.split("/").pop()

  return (
    <FormGroup className="pb-10 action-group application-action-group">
      <div className="inline-flex">
        {canApproveAssign && (
          <ApplicationTableRowsActions
            id={id}
            status={fullSubmission.workflowStatus}
            fromAppDetails
            hasWorkflow
            approvalUsers={approvalUsers}
          />
        )}
        {(canUpdateSubmission || currentpage === "add") && (
          <Button
            theme="primary"
            className="mx-2 d-table ml-auto btn"
            type="submit"
            disabled={submitButtonDisabled}
            onClick={() => saveApplication()}
          >
            {t(
              "module.applicationManager.component.applicationDetailsActions.save"
            )}
          </Button>
        )}
        {canRecalculate && (
          <Button
            theme="primary"
            className="mx-2 d-table ml-auto btn"
            type="submit"
            disabled={submitButtonDisabled}
            onClick={() => adjustAndRecalculate()}
          >
            {t(
              "module.applicationManager.component.applicationDetailsActions.recalculate"
            )}
          </Button>
        )}
        {canApprove && (
          <Button
            theme="accent"
            className="mx-2 text-white d-table ml-auto btn"
            type="submit"
            onClick={() => approveApplication()}
          >
            {t(
              "module.applicationManager.component.applicationDetailsActions.approve"
            )}
          </Button>
        )}
        {canApprove && (
          <Button
            theme="accent"
            className="mx-2 text-white d-table ml-auto btn"
            type="submit"
            onClick={() => declineApplication()}
          >
            {t(
              "module.applicationManager.component.applicationDetailsActions.decline"
            )}
          </Button>
        )}
        {canPrintorDownload() && (
          <Button
            theme="accent"
            className="mx-2 text-white d-table ml-auto btn"
            type="submit"
            onClick={() => handlePrint()}
            disabled={downloadInProgress}
          >
            {downloadInProgress ? "Downloading..." : "Download Report"}
          </Button>
        )}
        {canPrintorDownload() && (
          <Button
            theme="accent"
            className="mx-2 text-white d-table ml-auto btn"
            type="submit"
            onClick={() => handlePrint(true)}
            disabled={printInProgress}
          >
            {printInProgress ? "Printing..." : printText}
          </Button>
        )}
        <Button
          theme="secondary"
          className="mx-2 d-table ml-auto btn"
          type="submit"
          onClick={() => handleClose()}
        >
          {t(
            "module.applicationManager.component.applicationDetailsActions.close"
          )}
        </Button>
      </div>
    </FormGroup>
  )
}
